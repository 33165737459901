export default function StaticTexts() {
    return {
       guest: 'Tamu' ,
       id: 'Order id :',
       status: 'Status',
       pembelian: 'Tanggal Pembelian',
       pembayaran: 'Tanggal Pembayaran',
       pesanan: 'Rincian',
       price: 'Harga',
       expiredAt: 'Batas penyelesaian transaksi berlaku hingga',
       totalPembayaran: 'Total Pembayaran',
       lakukanPembayaran: 'Lakukan Pembayaran',
       expiredIn: 'Tenggat waktu pembayaran Anda telah berakhir',
       CREATED: 'Belum Bayar',
       UNDERPAYMENT: 'Diproses',
       PAID: 'Sudah Bayar',
       EXPIRED: 'Kadaluarsa',
       CANCELLED: 'Dibatalkan',
       copyId: 'Copy Id',
       detailPembeli: 'Detail Pembeli',
       batasTransaksi: 'Batas Transaksi',
       selesai: 'Selesai',
       bayar: 'Bayar',
       pilihMetode: 'Pilih metode pembayaran',
       caraMembayar: 'Cara membayar',
       batalkan: 'Batalkan',
       ubahMetode: 'Ubah Metode',
       cancelHeader: 'Transaksi Anda telah dibatalkan',
       successHeader: 'Transaksi Anda telah berhasil',
       expiredHeader: 'Transaksi Anda telah kadaluarsa',
       descriptionStatus1 : 'Transaksi Anda sudah tidak tersedia lagi sehingga tidak dapat kami proses',
       descriptionStatus2 :  'Silahkan mengulang proses checkout',
       descSuccess: 'Anda telah berhasil melakukan pembayaran melalui'
    }
}
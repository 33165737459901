<template>
  <div :ref="RefSkleton">
      &nbsp;
  </div>
</template>

<script>
export default {
    props: {
        RefSkleton: {
            type: String,
            default: 'skleton'
        },
        XWidth: {
            type: String,
            default: '300px'
        },
        XHeight: {
            type: String,
            default: '20px'
        },
    },
    mounted() {
        this.$refs[this.RefSkleton].style.width = this.XWidth 
        this.$refs[this.RefSkleton].style.margin = 'auto'
        this.$refs[this.RefSkleton].style.height = this.XHeight 
        let gradient = `linear-gradient( lightgray ${this.XWidth}, transparent 0 )`
        let backGroundImage = `linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ),
                ${gradient}`
        this.$refs[this.RefSkleton].style.backgroundImage = `${backGroundImage}`
        let sizebg = `120px 100px, ${this.XWidth} ${this.XHeight}`
        this.$refs[this.RefSkleton].style.backgroundSize = `${sizebg}`
        this.$refs[this.RefSkleton].style.backgroundRepeat = 'repeat-y'
        this.$refs[this.RefSkleton].style.backgroundPosition =  `0 0, 120px 0,`
        this.$refs[this.RefSkleton].style.animation = `shine 1s infinite`
    }
}
</script>
<style>
	@keyframes shine {
		to {
			background-position:
                100% 0
		}
	}
</style>
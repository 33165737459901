<template>
  <div class="customer-detail" id="grid-template">
    <div class="info-left pr-2" ref="left-info">
      <template v-if="isRunMobile">
        <span class="status-info" :class="status">
          {{ staticText[status] }}
        </span>
      </template>
      <p class="name span-ellipsis text-font-1 w-225">
        <span class="" v-if="detail.first_name || detail.last_name">
          {{ detail.first_name }} {{ detail.last_name }}</span
        >
        <span v-else-if="transactionOrder"> {{ guest }} </span>
        <rectangle-skleton
          v-if="isLoading"
          :ref-skleton="'name'"
          class="m-0 ml-2 mb-2"
          :x-width="'68px'"
          :x-height="'16px'"
        />
      </p>
      <p class="detail fs-12 mb-0 text-font-2">
        <span v-if="detail.phone">{{ detail.phone }}</span>
        <rectangle-skleton
          v-if="isLoading"
          :ref-skleton="'phone'"
          class="m-0 ml-2 mb-2"
          :x-width="'100px'"
          :x-height="'16px'"
        />
      </p>
      <p class="detail mb-0 text-font-3">
        <label class="span-ellipsis w-225" >{{ detail.email }}</label>
        <rectangle-skleton
          v-if="isLoading"
          :ref-skleton="'phone'"
          class="m-0 ml-2 mb-2"
          :x-width="'68px'"
          :x-height="'16px'"
        />
      </p>
    </div>
    <div class="info-right ">
      <div class="text-left">
        <label class="span-id text-font-3 mb-1">
          {{ id }} {{ transactionOrder }}
          <rectangle-skleton
            v-if="isLoading"
            :ref-skleton="'id-transaction'"
            class="mt-1 ml-1"
            :x-width="'50px'"
            :x-height="'14px'"
          />
        </label>
      </div>
      <button
        v-if="!isLoading"
        class="btn btn-sm btn-block btn-outline-caredokter btn-copy p-1"
        @click.stop="copyId(transactionOrder, $event)"
      >
        <i class="fa fa-clone" aria-hidden="true"></i>{{staticText['copyId']}}
      </button>
    </div>
  </div>
</template>
<script>
import RectangleSkleton from "../skleton/RectangleSkleton.vue";
import StaticText from "@/helpers/StaticText";
import { mapState } from "vuex";
export default {
  components: { RectangleSkleton },
  data() {
    return {
      guest: new StaticText().guest,
      id: new StaticText().id,
      staticText: new StaticText(),
    };
  },
  computed: {
    ...mapState({
      isRunMobile: (state) => state.isRunMobile,
    }),
  },
  mounted() {
   this.checkElipsis()
    window.onresize = ()=> {
      this.checkElipsis()
    }
  },
  props: {
    transactionOrder: {
      default: "",
      type: String,
    },
    detail: {
      type: [Object, String],
      default: () => {
        return {
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
        };
      },
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    status: {
      type: String,
      default: "",
    },
  },
  methods: {
    copyId(item, evt) {
      // eslint-disable-next-line no-undef
      helpers.copyValue(item, evt);
    },
    checkElipsis() {
          const innerWidthLeftInfo = this.$refs['left-info']
          const elippsis = document.getElementsByClassName('w-225')
          for(let i = 0; i < elippsis.length; i++) {
                elippsis[i].style.width = `${innerWidthLeftInfo.clientWidth}px`
          }
    }
  },
};
</script>
